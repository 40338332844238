// GoogleCalendarComponent.js
import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import './GoogleCalendarComponent.scss';

const GoogleCalendarComponent = () => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const email = localStorage.getItem('email');
    console.log('GoogleCalendarComponent::accessToken',accessToken)
    if (accessToken && email) {
      fetchEvents(accessToken, email);
    }
  }, []);
  
  const fetchEvents = async (accessToken, email) => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/calendar/v3/calendars/${email}/events`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      console.log('Fetched events:', data); // Log the fetched data
      if (data && data.items) {
        setEvents(data.items);
      } else {
        console.error('No items found in the fetched data:', data);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };
  

  return (<>
    <div className="grayline">
        <div className="divider"></div>
    </div>
    <div className="calendar-container">
        <h1>Calendar</h1>
      <FullCalendar
        plugins={[dayGridPlugin, googleCalendarPlugin]}
        initialView="dayGridMonth"
        events={events.map((event) => ({
          title: event.summary,
          start: event.start.dateTime || event.start.date,
          end: event.end.dateTime || event.end.date,
        }))}
      />
    </div>
    <div className="blueline">
        <div className="divider"></div>
    </div>
    </>);
};

export default GoogleCalendarComponent;