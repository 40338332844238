import { FETCH_ONE_PROJECT, FETCH_ALL_PROJECT} from "../helper";

const initState = {
    fetchOneData: {},
    fetchAllData: {},
}

export const projectReducer = (state = initState, action) =>{
    switch (action.type) {
        case FETCH_ONE_PROJECT:
            // console.log('reducer=> fetch one data')
            return {...state, fetchOneData: action.payload}
        case FETCH_ALL_PROJECT:
            // console.log('reducer=> fetch all data')
            return {...state, fetchAllData: action.payload}
        default:
            return state
    }
}