import React, { useEffect, useState } from 'react';
import Popup from './Popup';
import './HomePage.scss';

export const HomePage = () => {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    if(showPopup) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [showPopup]);

  return (<>
    <div className="home-page">
      <div className="grayline">
          <div className="divider"></div>
      </div>
      <div className="main-content no-scroll">
        <div className="main">
          <div className="main-title">
            <div>AI Native technology</div>
            <div>to assist the SME</div>
            <div>construction industry</div>
          </div>
          <div className="main-subtitle">Be the first to know</div>

          <button onClick={() => setShowPopup(true)}>Join the Waitlist </button>
          <div></div>
        </div>
        {showPopup && <Popup closePopup={() => setShowPopup(false)} />}    
        <div className="blueline">
            <div className="divider"></div>
        </div>
      </div>
    </div>
    </>);
}
