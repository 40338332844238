import React from 'react';
import './ConstructionExpensesComponent.scss'; // Import the SCSS file for styling

const ConstructionExpensesComponent = ({ constructionExpenses }) => {
  return (
    <div className="construction-expenses">
      <h2 className="expenses-heading">Construction Expenses</h2>
      {constructionExpenses.length > 0 ? (
        <table className="expenses-table">
          <thead>
            <tr>
              <th>Date</th>
              <th>Paid By</th>
              <th>Category</th>
              <th>Expense Amount</th>
              <th>Received By</th>
            </tr>
          </thead>
          <tbody>
            {constructionExpenses.map((expense) => (
              <tr key={expense.expense_id}>
                <td className="expenses-table-td-date">{expense.date}</td>
                <td className="expenses-table-td-paidBy">{expense.paid_by || 'N/A'}</td>
                <td className="expenses-table-td-category">{expense.category}</td>
                <td className="expenses-table-td-amount">${expense.expense_amount}</td>
                <td className="expenses-table-td-receivedBy">{expense.received_by || 'N/A'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No construction expenses available for this project.</p>
      )}
    </div>
  );
};

export default ConstructionExpensesComponent;
