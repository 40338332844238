import React, { useState } from 'react';
import axios from 'axios'
import './Popup.scss';
import { SITEGENIE_WAITLIST_URL } from '../../helper'

function Popup({ closePopup }) {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const isFormValid = () => {
        return formData.firstName && formData.lastName && formData.email && formData.phone;
    };

    const submitToAPI = () => {
        const data = {
            first_name: formData.firstName,
            last_name: formData.lastName,
            email: formData.email,
            contact_number: formData.phone
        };

        axios.post(SITEGENIE_WAITLIST_URL, data)
            .then(response => {
                console.log(response.data);
                alert(response.data.message);
                closePopup();
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isFormValid()) {
            submitToAPI(); 
            alert("Thank you for joining our waiting list!");
            closePopup(); 
        } else {
            alert("Please fill in all the fields.");
        }
    };

    return (
        <div className="popup">
            <div className="popup-content">
                <div className="popup-header">
                    <h2>Join the Waitlist</h2>
                    <button onClick={closePopup} className="close-button">&times;</button>
                </div>
                <p>By submitting this form, your firm will join our waitlist to get early access to SiteGenie. When your firm has been selected, we will reach out to set up a demo.</p>
                <form onSubmit={handleSubmit}>
                    <div className="form-group-row">
                        <div className="form-group-row-column">
                            <div className="label">First Name</div>
                            <input type="text" name="firstName" placeholder="First Name" onChange={handleChange} value={formData.firstName} />
                        </div>
                        <div className="form-group-row-column">
                            <div className="label">Last Name</div>
                            <input type="text" name="lastName" placeholder="Last Name" onChange={handleChange} value={formData.lastName} />
                        </div>
                    </div>
                    <div className="form-group-row">
                        <div className="form-group-row-column">
                            <div className="label">Email</div>
                            <input type="email" name="email" placeholder="Email" onChange={handleChange} value={formData.email} />
                        </div>
                        <div className="form-group-row-column">
                            <div className="label">Phone</div>
                            <input type="tel" name="phone" placeholder="Phone" onChange={handleChange} value={formData.phone} />
                        </div>
                    </div>
                    <button type="submit" className="submit-button" disabled={!isFormValid()}>Submit</button>
                </form>
            </div>
        </div>
    );
}

export default Popup;
