import React, { useState } from 'react';
import './aboutPage.scss';
//import linkedinIcon from './linkedin_icon.svg';

export const AboutPage = () => {
  const [showBio, setShowBio] = useState(false);

  const leadershipMembers = [
    { name: 'Catherine Li', position: 'Founder & CEO', bio: '', img: 'Catherine-Linkdin.jpg' },
    { name: 'Sally Dong', position: 'Co-Founder', bio: 'CFA, CGA, MBA', img: 'Sally-Linkdin-Profile.jpeg' },
    { name: 'Liqun Fu', position: 'Co-Founder', bio: '', img: 'female.png' },
  ];

  const technical1Members = [
    { name: 'Atif', position: 'Machine Learning Advisor', bio: '', img: 'male.png' },
    { name: 'Judy', position: 'Full Stack Development Advisor', bio: 'CEO of a Software Company', img: 'female.png' },
    { name: 'Michael', position: 'AI Consultant', bio: '', img: 'male.png' },
    { name: 'Rohan Jhonson', position: 'Full Stack Developer', bio: '', img: 'Rohan-Linkdin.jpg' },
  ];

  const technical2Members = [
    { name: 'Raphael Zhou', position: 'Full Stack Developer', bio: '', img: 'You-Zhou.png' },
    { name: 'Derrick Hua', position: 'Full Stack Developer', bio: '', img: 'Derrick-Ai-Profile.png' },
    { name: 'Christopher Morrison', position: 'Front End Developer', bio: '', img: 'male.png' },
    { name: 'Chloe Lee', position: 'UX/UI Designer', bio: '', img: 'Chloe-Ai-Profile.png' },
  ];

  const technical3Members = [
    { name: 'Jessie Zheng', position: 'UX/UI Designer', bio: '', img: 'Jess-Ai-Profile.png' },
    { name: 'David Clement', position: 'Graphic Designer', bio: '', img: 'David-Ai-Profile.png' },
  ];

  const industryTestingMembers = [
    { name: 'William Wong', position: 'Senior Engineer', bio: 'P.Eng, OBOA, Chief Building Official', img: 'male.png' },
    { name: 'Magid Youssef', position: 'Senior Architect', bio: 'LEED, OAA', img: 'male.png' },
    { name: 'Helya Mozafari', position: 'Project Planner Manager', bio: '', img: 'Heyla-Ai-Profile.png' },
    { name: 'Joseph Battaglia', position: 'Senior Architect', bio: '', img: 'Joe-QH.png' },
  ];

  return (<div>
    <div className="grayline">
          <div className="divider"></div>
    </div>
    <div className="about-page" id="about">
      <div className="about-video-background">
        <video playsInline autoPlay muted loop id="bgVideo">
          <source src="about-bg.mp4" type="video/mp4" />
            Your browser does not support HTML5 video。
        </video>
        <div className="about-page-content">
          <div className="about-page-title">About Our Team</div>
          <div className="about-page-subtitle">Sitegenie, your wish is my command.</div>
          <div className="about-page-paragraph">We are an innovative company that specializes in AI Technology to help facilitate the construction process by integrating advanced technology. Established in 2023 and headquartered in Toronto, we have performed extensive research and understanding of the construction industry to curate a unique and simple application for the construction industry.</div>
        </div>
      </div>
      <div className="about-page-team-members-container">
        {/*Leadership*/}
        <div className="about-page-team-member-content">
          <div className="about-page-team-member-type">Leadership</div>
          <div className="about-page-team-member-intro">
            {leadershipMembers.map((member, index) => (
              <div key={index} className="about-page-team-member" onMouseEnter={() => setShowBio(true)} onMouseLeave={() => setShowBio(false)}>
                <div className="about-page-team-member-avatar">
                  <img src={member.img} alt="avator" />
                </div>
                <div className="about-page-team-member-info">
                    <div className="about-page-team-member-name">{member.name}</div>
                    <div className="about-page-team-member-position">{member.position}</div>
                </div>
                {showBio && <div className="about-page-team-member-bio">{member.bio}</div>}
              </div>
            ))}
          </div>
        </div>
        {/*Technical1Team*/}
        <div className="about-page-team-member-content">
          <div className="about-page-team-member-type">TechnicalTeam</div>
          <div className="about-page-team-member-intro">
            {technical1Members.map((member, index) => (
              <div key={index} className="about-page-team-member" onMouseEnter={() => setShowBio(true)} onMouseLeave={() => setShowBio(false)}>
              <div className="about-page-team-member-avatar">
                <img src={member.img} alt="avator" />
              </div>
              <div className="about-page-team-member-info">
                  <div className="about-page-team-member-name">{member.name}</div>
                  <div className="about-page-team-member-position">{member.position}</div>
              </div>
              {showBio && <div className="about-page-team-member-bio">{member.bio}</div>}
            </div>
            ))}
          </div>
        </div>
          {/*Technical2Team*/}
          <div className="about-page-team-member-content">
            <div className="about-page-team-member-intro">
              {technical2Members.map((member, index) => (
                <div key={index} className="about-page-team-member" onMouseEnter={() => setShowBio(true)} onMouseLeave={() => setShowBio(false)}>
                  <div className="about-page-team-member-avatar">
                    <img src={member.img} alt="avator" />
                  </div>
                  <div className="about-page-team-member-info">
                    <div className="about-page-team-member-name">{member.name}</div>
                    <div className="about-page-team-member-position">{member.position}</div>
                  </div>
                  {showBio && <div className="about-page-team-member-bio">{member.bio}</div>}
                </div>
              ))}
            </div>
          </div>
          {/*Technical3Team*/}
          <div className="about-page-team-member-content">
            <div className="about-page-team-member-intro">
              {technical3Members.map((member, index) => (
                <div key={index} className="about-page-team-member" onMouseEnter={() => setShowBio(true)} onMouseLeave={() => setShowBio(false)}>
                  <div className="about-page-team-member-avatar">
                    <img src={member.img} alt="avator" />
                  </div>
                  <div className="about-page-team-member-info">
                    <div className="about-page-team-member-name">{member.name}</div>
                    <div className="about-page-team-member-position">{member.position}</div>
                  </div>
                  {showBio && <div className="about-page-team-member-bio">{member.bio}</div>}
                </div>
              ))}
            </div>
          </div>
        {/*IndustryTestingTeam*/}
        <div className="about-page-team-member-content">
          <div className="about-page-team-member-type">IndustryTestingTeam</div>
          <div className="about-page-team-member-intro">
            {industryTestingMembers.map((member, index) => (
              <div key={index} className="about-page-team-member" onMouseEnter={() => setShowBio(true)} onMouseLeave={() => setShowBio(false)}>
              <div className="about-page-team-member-avatar">
                <img src={member.img} alt="avator" />
              </div>
              <div className="about-page-team-member-info">
                  <div className="about-page-team-member-name">{member.name}</div>
                  <div className="about-page-team-member-position">{member.position}</div>
              </div>
              {showBio && <div className="about-page-team-member-bio">{member.bio}</div>}
            </div>
            ))}
          </div>
        </div>
      </div>
    </div>
    <div className="blueline">
            <div className="divider"></div>
      </div>
  </div>);
}
