import React, { useEffect } from 'react';
import './projectManagementPage.scss'; // Import the SCSS file for styling
import { useDispatch, useSelector } from 'react-redux';
import { fetchOne } from '../../actions/fetchProjectAction';
import TasksComponent from '../TasksComponent/TasksComponent';
import ConstructionExpensesComponent from '../ConstructionExpensesComponent/ConstructionExpensesComponent';
import ProjectFilesComponent from '../ProjectFilesComponent/ProjectFilesComponent';
import InspectionStagesComponent from '../InspectionStagesComponent/InspectionStagesComponent';

export const ProjectManagementPage = ({projectId}) => {
    // Raphael Start
    const fetchOneData = useSelector(state => state?.projectReducer.fetchOneData);
    const dispatch = useDispatch()
    useEffect(() => dispatch(fetchOne(projectId)),[]);
    console.log("fetch One Data =>>>>>", fetchOneData);
    // Raphael End

  return (<>
    <div className="grayline">
      <div className="divider"></div>
  </div>
  <div className="project-page-grid-container">
    <h1>{fetchOneData.data[0].name}</h1>
    {/* Nested grid to display inspection stages */}
    {fetchOneData.data[0].inspection_stages && fetchOneData.data[0].inspection_stages.length > 0 ? (
        <InspectionStagesComponent inspectionStages={fetchOneData.data[0].inspection_stages} />
      ) : (
        <h2 className="project-page-tasks-heading">No inspection stages available for {fetchOneData.data[0] ? fetchOneData.data[0].name : "this project"}</h2>
      )}
    {/* Nested grid to display project tasks */}
    {fetchOneData.data[0].project_tasks && fetchOneData.data[0].project_tasks.length > 0 ? (
          <TasksComponent selectedProjectTasks={fetchOneData.data[0].project_tasks} />
        ) : (
          <h2 className="project-page-tasks-heading">No tasks available for {fetchOneData.data[0] ? fetchOneData.data[0].name : "this project"}</h2>
        )}
    {/* Nested grid to display project tasks */}
    {fetchOneData.data[0].constrction_expenses && fetchOneData.data[0].constrction_expenses.length > 0 ? (
          <ConstructionExpensesComponent constructionExpenses={fetchOneData.data[0].constrction_expenses} />
        ) : (
          <h2 className="project-page-tasks-heading">No construction expenses available for {fetchOneData.data[0] ? fetchOneData.data[0].name : "this project"}</h2>
        )}
    {/* Render ProjectFilesComponent */}
    {fetchOneData.data[0].files && fetchOneData.data[0].files.length > 0 ? (
          <ProjectFilesComponent files={fetchOneData.data[0].files} />
        ) : (
          <h2 className="project-page-tasks-heading">No files available for {fetchOneData.data[0] ? fetchOneData.data[0].name : "this project"}</h2>
        )}
  </div>
  <div className="blueline">
      <div className="divider"></div>
  </div>
  </>);
};