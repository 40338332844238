import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './projectsPage.scss'; // Assuming you have a SCSS file with this name
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import googleCalendarPlugin from '@fullcalendar/google-calendar';
import { useAuth } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';
import { SITEGENIE_PROJECT_TEST_URL } from '../../helper';
import { fetchOne } from '../../actions/fetchProjectAction';

export const ProjectsPage = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(10);
  const [projectsData, setProjectsData] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const history = useNavigate();
  const { isLoggedIn, login, logout } = useAuth();

  const userId = localStorage.getItem('userId');
  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const email = localStorage.getItem('email');
  const companyId = localStorage.getItem('companyId');

  // useEffect(() => {
  //   // Fetch data from the API
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(SITEGENIE_PROJECT_TEST_URL);
  //       const responseData = await response.json();
  //       console.log("API response data:", responseData);
  //       // Extract and set project data
  //       setProjectsData(responseData.data); // Assuming responseData.data contains the array of projects
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   };
  
  //   fetchData();
  // }, []);

  const fetchData = async (companyId) => {
    try {
      const userId = localStorage.getItem('userId');
      const email = localStorage.getItem('email');

      //const url = `https://localhost:7046/api/UsersControllerv1/GetCompanyWiseProjectsDataForPortal?intUserID=${userId}&strEmail=${email}&intCompanyID=${companyId}`;
      const url = `https://api.sitegenie.ai/api/UsersControllerv1/GetCompanyWiseProjectsDataForPortal?intUserID=${userId}&strEmail=${email}&intCompanyID=${companyId}`;
      const response = await fetch(url);
      const responseData = await response.json();

      console.log("API response data:", responseData);

      if (response.ok) {
        setProjectsData(responseData.data); // Assuming responseData.data contains the array of projects
      } else {
        console.error('Failed to fetch data:', responseData.error);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleClick = (project) => {
    setSelectedProject(project);
    console.log("handleClick fetch one project_id => ", project.project_id);
    history(`/project/:${project.project_id}`);
    //navigate('/projectmanagement');
  };

  // const extractTokenAndEmail = () => {
  //   const params = new URLSearchParams(window.location.search);
  //   const accessToken = params.get('accessToken');
  //   const email = params.get('email');
  //   login();
  //   localStorage.setItem('accessToken', accessToken);
  //   localStorage.setItem('email', email);
  //   history(window.location.pathname);
  // };

  const extractTokenAndEmail = () => {
    const params = new URLSearchParams(window.location.search);
    const userId = params.get('userId');
    const firstName = params.get('firstName');
    const lastName = params.get('lastName');
    const email = params.get('email');
    const companyId = params.get('companyId');

    if (userId && firstName && lastName && email && companyId) {
        // Assuming you use these values to log in the user or set them in local storage
        localStorage.setItem('userId', userId);
        localStorage.setItem('firstName', firstName);
        localStorage.setItem('lastName', lastName);
        localStorage.setItem('email', email);
        localStorage.setItem('companyId', companyId);
        login();
        history(window.location.pathname);

        // Fetch data with companyId from URL
        fetchData(companyId);
    }
    else {
      // Retrieve values from local storage
    const storedUserId = localStorage.getItem('userId');
    const storedFirstName = localStorage.getItem('firstName');
    const storedLastName = localStorage.getItem('lastName');
    const storedEmail = localStorage.getItem('email');
    const storedCompanyId = localStorage.getItem('companyId');
    fetchData(storedCompanyId);

    }
  };

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    const storedEmail = localStorage.getItem('email');
    // If userId and email are not stored, extract them from URL
    if (!storedUserId || !storedEmail) {
      extractTokenAndEmail();
    } else {
      // If already stored, continue with the existing logic
      extractTokenAndEmail();
    }
  }, []);

// Google Calendar
const [events, setEvents] = useState([]);

useEffect(() => {
    const accessToken = localStorage.getItem('accessToken');
    const email = localStorage.getItem('email');
    console.log('GoogleCalendarComponent::accessToken',accessToken)
    if (accessToken && email) {
      fetchEvents(accessToken, email);
    }
}, []);
  
const fetchEvents = async (accessToken, email) => {
    try {
      const response = await fetch(
        `https://www.googleapis.com/calendar/v3/calendars/${email}/events`,
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      console.log('Fetched events:', data); // Log the fetched data
      if (data && data.items) {
        setEvents(data.items);
      } else {
        console.error('No items found in the fetched data:', data);
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
};

 // Pagination logic
const indexOfLastRow = currentPage * rowsPerPage;
const indexOfFirstRow = indexOfLastRow - rowsPerPage;
const currentRows = projectsData.slice(indexOfFirstRow, indexOfLastRow);

const paginate = (pageNumber) => setCurrentPage(pageNumber);

return (<>
  <div className="grayline">
      <div className="divider"></div>
  </div>
  <div className="projects-page-container">
    <div className="projects-page-container-left">
        <table className="projects-page-container-left-table">
            <thead>
                <tr>
                    <th>Project Name</th>
                    <th>Status</th>
                    {/* Add more column headers as needed */}
                </tr>
            </thead>
            <tbody>
            {currentRows.map((project, index) => (
            <tr key={index} onClick={() => handleClick(project)}>
                <td>{project.name}</td>
                <td>{project.status}</td>
                {/* Render more columns as needed */}
            </tr>
            ))}
            </tbody>
        </table>
        {/* Pagination */}
        <div className="projects-page-pagination">
        {projectsData.length > rowsPerPage && (
            <ul>
            {Array.from({ length: Math.ceil(projectsData.length / rowsPerPage) }).map((_, index) => (
                <li key={index}>
                    <button onClick={() => paginate(index + 1)}>{index + 1}</button>
                </li>
            ))}
            </ul>
        )}
        </div>
    </div>
    <div className="projects-page-container-right">
        <FullCalendar
            plugins={[dayGridPlugin, googleCalendarPlugin]}
            initialView="dayGridMonth"
            events={events.map((event) => ({
                title: event.summary,
                start: event.start.dateTime || event.start.date,
                end: event.end.dateTime || event.end.date,
            }))}
        />
    </div>
  </div>
  <div className="blueline">
      <div className="divider"></div>
  </div>
  </>);

};
