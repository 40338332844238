import React from 'react';
import './Footer.scss';

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-top">
          <h1>Sitegenie</h1>
      </div>
      <div className="footer-middle">
        <p>Contact Us via</p>
        <a href="mailto:Support@Sitegenie.ai">Support@Sitegenie.ai</a>
      </div>
      <div className="footer-bottom">
        <p>@2024 by Sitegenie.</p>
        <div></div>
      </div>
      {/*<div className="footer-bottom">
        <div className="footer-section-left">
          <p className="footer-section-left-copyright">Copyright © 2024 SiteGenie.AI, Inc. All rights reserved.</p>
          <p className="footer-section-left-copyright">Contact us on: contact@sitegenie.ai</p>
          <p>Follow us on:
            <a href="https://www.linkedin.com/company/sitegenie-ai/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin-in"></i>
            </a>
          </p>
        </div>
        <div className="footer-section-right">
          <p className="footer-section-right-company">Company</p>
          <p>1050 McNicoll Ave, Scarborough</p>
          <p>Ontario M1W 2L8, CA</p>
        </div>
      </div>*/}
    </footer>
  );
}
