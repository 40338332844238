import { SITEGENIE_PROJECT_TEST_URL, SITEGENIE_PROJECTID_TEST_URL, FETCH_ONE_PROJECT, FETCH_ALL_PROJECT } from "../helper";
import axios from "axios";

export const fetchAll = () => dispatch => {
    axios.get(SITEGENIE_PROJECT_TEST_URL)
        .then(res => {
            let fetchAllData = res.data
            dispatch({
                type: FETCH_ALL_PROJECT,
                payload: fetchAllData
            })
        })
        .catch(err => console.log("fetch all data error!", err))
}

export const fetchOne = projectId => dispatch => {
    console.log("fetch one action =>", projectId);
    axios.get(SITEGENIE_PROJECTID_TEST_URL + '?intProjectID=' + projectId)
        .then(res => {
            let fetchOneData = res.data
            dispatch({
                type: FETCH_ONE_PROJECT,
                payload: fetchOneData,
            })
        })
        .catch(err => console.log("fetch one data error!", err))
}