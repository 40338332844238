import React from 'react';
import './ProjectFilesComponent.scss'; // Import the SCSS file for styling

const ProjectFilesComponent = ({ files }) => {
  return (
    <div className="project-files">
      <h2 className="files-heading">Project Files</h2>
      {files.length > 0 ? (
        <div className="files-list">
          {files.map((file) => (
            <div key={file.file_id} className="file-item">
              <div className="file-info">
                <div className="file-name">
                  <a href={file.link} target="_blank" rel="noopener noreferrer">{file.file_name}</a>
                </div>
                <div className="ai-generated">
                  <label>
                    <input type="checkbox" disabled checked={file.b_ai_generated} />
                    AI Generated
                  </label>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No files available for this project.</p>
      )}
    </div>
  );
};

export default ProjectFilesComponent;
