import React from 'react';
import './testimonialsPage.scss';

export const TestimonialsPage = () => {
  return (<div>
    <div className="grayline">
        <div className="divider"></div>
    </div>
    <div className="testimonials-page" id="testimonials">
        <div className="testimonials-page-intro">
            <div className="testimonials-page-title">This is Sitegenie.</div>
            <div className="testimonials-page-subtitle">
                Our team is driven to create and innovate a solution to simplify the communication and dada input process for small to medium size construction projects.
            </div>
            <div className="testimonials-page-subtitle">
                We tackle these challenges by minimizing the manual input by organizing and gathering data through the help of AI technology.
            </div>
        </div>
        <div className="testimonials-page-feedback">
            <div className="testimonials-page-feedback-block">
                <div className="testimonials-page-feedback-block-sign">
                    <img src="/quote.png" alt="quote_mark"></img>
                </div>
                <div className="testimonials-page-feedback-block-word">"Finally, someone to help me with Paperwork"</div>
                <div className="testimonials-page-feedback-block-author">- Fabio C, Drywaller, Ajax, ON</div>
            </div>
            <div className="testimonials-page-feedback-block">
                <div className="testimonials-page-feedback-block-sign">
                    <img src="/quote.png" alt="quote_mark"></img>
                </div>
                <div className="testimonials-page-feedback-block-word">"The assistant I've always wanted."</div>
                <div className="testimonials-page-feedback-block-author">- Helya M, Project Coordinator, Toronto, ON</div>
            </div>
            <div className="testimonials-page-feedback-block">
                <div className="testimonials-page-feedback-block-sign">
                    <img src="/quote.png" alt="quote_mark"></img>
                </div>
                <div className="testimonials-page-feedback-block-word">"This app got it right, no more data entry."</div>
                <div className="testimonials-page-feedback-block-author">- Bowen H, Project Manager, Toronto, ON</div>
            </div>
        </div>
    </div>
    <div className="blueline">
        <div className="divider"></div>
    </div>
    </div>);
}
