import React from 'react';
import './InspectionStagesComponent.scss'; // Import the SCSS file for styles

const InspectionStagesComponent = ({ inspectionStages }) => {
  // Sort the inspection stages based on stage_id in ascending order
  const sortedInspectionStages = inspectionStages.slice().sort((a, b) => a.stage_id - b.stage_id);

  return (
    <div className="inspection-stages-container">
      {sortedInspectionStages && sortedInspectionStages.length > 0 ? (
        <div>
          <h2 className="inspection-stages-heading">Inspection Stages for {sortedInspectionStages[0].project_name}</h2>
          <table className="inspection-stages-table">
            <thead>
              <tr>
                <th>Stage Name</th>
                <th>When to Call for Inspection</th>
                <th>Completed</th>
                {/* Add more column headers as needed */}
              </tr>
            </thead>
            <tbody>
              {sortedInspectionStages.map((stage, index) => (
                <tr key={index}>
                  <td>{stage.stage_name}</td>
                  <td>{stage.when_to_call_for_inspection}</td>
                  <td>{stage.completed ? 'Yes' : 'No'}</td>
                  {/* Render more columns as needed */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          <h2 className="inspection-stages-heading">No inspection stages available for</h2>
        </div>
      )}
    </div>
  );
};

export default InspectionStagesComponent;
