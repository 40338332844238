import React, {useEffect} from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOne } from './actions/fetchProjectAction';
import { HomePage } from './components/homePage/HomePage';
import { FeaturesPage } from './components/featuresPage/featuresPage';
import { Header } from './components/Header/Header';
import { Footer } from './components/Footer/Footer';
import { TestimonialsPage } from './components/testimonialsPage/testimonialsPage';
import { AboutPage } from './components/aboutPage/aboutPage';
import { ContactPage } from './components/contactPage/contactPage';
import { ProjectsPage } from './components/projectsPage/projectsPage';
import { ProjectManagementPage } from './components/projectManagementPage/projectManagementPage';
import { AuthenticationErrorPage } from './components/ErrorPage/AuthenticationErrorPage';
import GoogleCalendarComponent from './components/GoogleCalendarComponent/GoogleCalendarComponent';
import { AuthProvider } from './AuthContext'; // Import AuthProvider

const SelectedProjectPage = () => {
  let {projectId} = useParams()
  projectId = projectId.replace(":","");

  const fetchOneData = useSelector(state => state?.projectReducer?.fetchOneData);
  const dispatch = useDispatch();
  useEffect(() => dispatch(fetchOne(projectId)),[]);

  return (<div>
    {JSON.stringify(fetchOneData) !== '{}' && <ProjectManagementPage projectId={projectId}/>}
  </div>)
}

const NoMatch = () => {
  return (
    <div></div>
  )
}

export const App = () => {
  return (
    <AuthProvider> {/* Place AuthProvider here */}
      <>
        <Header />
        <Routes>
          <Route index path="/" element={<HomePage />} />
          <Route path="/features" element={<FeaturesPage />} />
          <Route path="/testimonials" element={<TestimonialsPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/project" element={<ProjectsPage />} />
          <Route path="/projectmanagement" element={<ProjectManagementPage />} />
          <Route path="/project/:projectId" element={<SelectedProjectPage />} />
          <Route path="/authenticationerror" element={<AuthenticationErrorPage />} />
          <Route path="/calendar" element={<GoogleCalendarComponent />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
        <Footer />
        {/* <HamburgerMenu /> */}
      </>
    </AuthProvider>
  )
}
