import React from 'react';
import './featuresPage.scss';

export const FeaturesPage = () => {
  return (
    <div className="features-page" id="features">
      <div className="grayline">
          <div className="divider"></div>
      </div>
      <div className="features-video">
        <video controls autoPlay>
          <source src="videoMadeWithAIver2.mp4" type="video/mp4" />
            Your browser does not support HTML5 video。
        </video>
      </div>
      <div className="features-content">
        <div className="features-content-left">
          <div className="features-content-left-title">The AI assistant capable of being omnipresent at all times.</div>
          <div className="features-content-left-paragraph">
            Sitegenie redefines construction project management by integrating voice recognition and AI, providing professional and efficient solutions for modern construction teams. We can transform the industry by reducing delays and improving overall productivity.
          </div>
          <div className="features-content-left-paragraph">
            Our app also features real-time collaboration and smart planning assistance.
          </div>
        </div>
        <div className="features-content-right">
          <div className="features-content-right-details">
            <div className="features-content-right-details-title">1 | Digitize</div>
            <div className="features-content-right-details-paragrpah">
              Voice recognition and let Sitegenie Bot understand and execute them. From updating project timelines to assigning tasks! Sitegenie harnesses the power of AI to analyze voice data and extra insights. The app can identify patterns, assess project risks and provide predictive analytics.
            </div>
          </div>
          <div className="features-content-right-details">
            <div className="features-content-right-details-title">2 | Synchronize</div>
            <div className="features-content-right-details-paragrpah">
              Sitegenie harnesses the power of AI to analyze voice data and extra insights. The app can identify patterns, assess project risks and provide predictive analytics.
            </div>
          </div>
          <div className="features-content-right-details">
            <div className="features-content-right-details-title">3 | Cutting Edge AI</div>
            <div className="features-content-right-details-paragrpah">
              Seamless scheduling and management of construction meetings is simplified with efficient tools. Streamlining the process with AI allows for easy coordination, enhancing overall project communication and ensuring effective collaboration during construction meetings.
            </div>
          </div>
          <div className="features-content-right-details">
            <div className="features-content-right-details-title">4 | Real time updates</div>
            <div className="features-content-right-details-paragrpah">
              Real-time updates are crucial for efficient project planning and safety. Instant access to current information enables swift decision-making, optimizing project efficiency and proactively addressing safety risks for a secure working environment.
            </div>
          </div>
        </div>
      </div>
      <div class="blueline">
            <div class="divider"></div>
        </div>
    </div>
  );
}
