import React, { useState, useRef, useEffect  } from 'react';
import { Link } from 'react-router-dom';
import './Header.scss';
import { PopupMenu } from './PopupMenu';
import axios from 'axios';
import HamburgerMenu from '../HamburgerMenu/HamburgerMenu';
import { useAuth } from '../../AuthContext'; 
import { SITEGENIE_AUTH_URL, SITEGENIE_URL } from '../../helper';
//import { LoginComponent } from '../LoginComponent/LoginComponent';

export const Header = () => {
  // Raphael start 20240406
  const [showDropdownMenu, setShowDropdownMenu] = useState(false);
  const toggleDropdownMenu = () => { setShowDropdownMenu(!showDropdownMenu);};
  // Raphael end 20240406
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { isLoggedIn , logout} = useAuth();
  const [errorMessage, setErrorMessage] = useState('');
  const [isHamburgerMenuOpen, setIsHamburgerMenuOpen] = useState(false); 
  const hamburgerMenuRef = useRef(null);

  const userId = localStorage.getItem('userId');
  const firstName = localStorage.getItem('firstName');
  const lastName = localStorage.getItem('lastName');
  const email = localStorage.getItem('email');
  const companyId = localStorage.getItem('companyId');
  // Construct the URL with query parameters
  const url = `https://demo.sitegenie.ai/chat_interface?userId=${userId}&firstName=${firstName}&lastName=${lastName}&email=${encodeURIComponent(email)}&companyId=${companyId}`;
  // Define handleLogin function
  const handleLogin = async () => {
    try {
      const response = await axios.get(SITEGENIE_AUTH_URL);
      if (response.data && response.data.success) {
        const authUrl = response.data.data;
        window.location.href = authUrl;
      } else {
        console.error('Failed to fetch Google authentication URL.');
        setErrorMessage('Failed to initiate Google authentication.');
      }
    } catch (error) {
      console.error('Error:', error);
      console.error('Failed to fetch Google authentication URL.');
      setErrorMessage('Failed to initiate Google authentication.');
    }
  };

  // Define handleLogout function
  // const handleLogout = () => {
  //   // Implement logout logic here
  //   logout(); // Update isLoggedIn state internally
  // };

  const handleLogout = () => {
    // Clear localStorage
    localStorage.removeItem('accessToken');
    localStorage.removeItem('email');
    logout();
    // Redirect to Homescreen URL
    window.location.href = SITEGENIE_URL;
  };
  const handleToggleHamburgerMenu = () => {
    setIsHamburgerMenuOpen(prev => !prev);
  };

  const handleMenuItemClick = () => {
    setIsHamburgerMenuOpen(false);
  };

  const handleClickOutsideMenu = (event) => {
    if (hamburgerMenuRef.current && !hamburgerMenuRef.current.contains(event.target)) {
      setIsHamburgerMenuOpen(false);
    }
  };
  console.log('isHamburgerMenuOpen:', isHamburgerMenuOpen); 
  
  useEffect(() => {
    document.addEventListener('click', handleClickOutsideMenu);
    return () => {
      document.removeEventListener('click', handleClickOutsideMenu);
    };
  }, []);

  /*
  return (
    <header className="header">
    {isLoggedIn && (
        <React.Fragment>
          <div className="web-menu-icon" onClick={handleToggleHamburgerMenu}>☰</div>
          <HamburgerMenu isOpen={isHamburgerMenuOpen} ref={hamburgerMenuRef}  onItemClick={handleMenuItemClick} />
        </React.Fragment>
      )}
      <div className="header-left">
        <div className="logo"><Link to="/"><img src="/Sitegenie-Tagline.png" alt="logo"></img></Link></div>
      </div>
      <div className="header-right">
        <div className="header-right-bigsize">
          <div className="nav-section"><Link to="/features">Features</Link></div>
          <div className="nav-section"><Link to="/testimonials">Testimonials</Link></div>
          <div className="nav-section"><Link to="/about">About</Link></div>
          <div className="nav-section"><Link to="/contact">Contact Us</Link></div>
          {isLoggedIn ? (
            <div className="nav-section" onClick={handleLogout}>Logout</div> // Render user profile when logged in
          ) : (
            <div className="nav-section"><Link to="#" onClick={handleLogin}>Login</Link></div> // Render login button when not logged in
            //<div className="nav-section"><Link to="#"></Link>{<LoginComponent />}</div>
          )}
        </div>
        <div className="menu-icon" onClick={() => setIsPopupOpen(true)}>☰</div>
        {isPopupOpen && <PopupMenu closePopupMenu={() => setIsPopupOpen(false)} />}
      </div>
    </header>
  );
  */

  return (
    <header className="header">
      <div className="header-left">
        <div className="logo"><Link to="https://sitegenie.ai"><img src="/Sitegenie-Tagline.png" alt="logo"></img></Link></div>
      </div>
      <div className="header-right">
        <div className="header-right-bigsize">
          {isLoggedIn ? (
            <div>
              <div className="nav-section"><Link to={url}>Genie AI</Link></div>
              <div className="nav-section"><Link to="/project">Project</Link></div>
              <div className="nav-section"><Link to="/calendar">Calendar</Link></div>
              <div className="nav-section" onClick={handleLogout}>Logout</div>
            </div>
          ) : (
          <div>
            <div className="nav-section"><Link to="/features">Features</Link></div>
            <div className="nav-section"><Link to="/testimonials">Testimonials</Link></div>
            <div className="nav-section"><Link to="/about">About</Link></div>
            <div className="nav-section"><Link to="/contact">Contact Us</Link></div>
            <div className="nav-section"><Link to="#" onClick={handleLogin}>Login</Link></div>
          </div>
          )}
        </div>
        {/*<div className="menu-icon" onClick={() => setIsPopupOpen(true)}>☰</div>*/}
        {/*isPopupOpen && <PopupMenu closePopupMenu={() => setIsPopupOpen(false)} />*/}
        <div className="menu-icon" onClick={toggleDropdownMenu}>☰</div>
        {showDropdownMenu && <PopupMenu closePopupMenu={() => setShowDropdownMenu(false)}/>}
      </div>
    </header>
  );
}
