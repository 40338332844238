import React from 'react';
import './AuthenticationErrorPage.scss'; // Assuming you have a SCSS file with this name
import { Link } from 'react-router-dom';

export const AuthenticationErrorPage = () => {
  return (<>
    <div className="grayline">
        <div className="divider"></div>
    </div>
    <div className="authentication-error-page">
      <header className="header">
        {/* <div className="header-left">
          <div className="logo"><Link to="/"><img src="/Sitegenie-Tagline.png" alt="logo" /></Link></div>
        </div> */}
        <div className="header-right">
          <div className="header-right-bigsize">
            {/* Add your navigation links here */}
          </div>
        </div>
      </header>
      <div className="error-message">
        <h1>You are not authorized to access the admin portal</h1>
        <p>Email us at <a href="mailto:support@sitegenie.ai">support@sitegenie.ai</a> to enroll and login.</p>
      </div>
    </div>
    <div className="blueline">
        <div className="divider"></div>
    </div>
    </>);
}

