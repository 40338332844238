import React from 'react';
import './TasksComponent.scss'; // Import the SCSS file for styles

const TasksComponent = ({ selectedProjectTasks }) => {
  return (
    <div className="tasks-container">
      {selectedProjectTasks && selectedProjectTasks.length > 0 ? (
        <div>
          <h2 className="tasks-heading">Tasks for {selectedProjectTasks[0].project_name}</h2>
          <table className="tasks-table">
            <thead>
              <tr>
                <th>Description</th>
                <th>Status</th>
                {/* Add more column headers as needed */}
              </tr>
            </thead>
            <tbody>
              {selectedProjectTasks.map((task, index) => (
                <tr key={index}>
                  <td>{task.task_description}</td>
                  <td>{task.status}</td>
                  {/* Render more columns as needed */}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div>
          <h2 className="tasks-heading">No tasks available for</h2>
        </div>
      )}
    </div>
  );
};

export default TasksComponent;
