import React from 'react';
import './contactPage.scss';

export const ContactPage = () => {
  return (<div>
    <div className="grayline">
          <div className="divider"></div>
    </div>
    <div className="contact-page">
      <div className="contact-page-intro">
        <div className="contact-page-left">
          <div className="contact-page-title">Contact Us</div>
          <div className="contact-page-address">1050 McNicoll Ave Unit 13-14, Scarborough, ON M1W 2L8</div>
          <div className="contact-page-email"><a href="mailto:Support@Sitegenie.ai">Support@Sitegenie.ai</a></div>
          <div className="contact-page-phone"></div>
        </div>
        {/*<div className="contact-page-right">
          <img src="/contact-1.jpg" alt="contact_picture"></img>
        </div>*/}
      </div>
      <div className="contact-page-map">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20521.53223463605!2d-79.35217152902486!3d43.80751027868702!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d4d3a036718fa3%3A0x35306d40368debb4!2s1050%20McNicoll%20Ave%2C%20Toronto%2C%20ON%20M1W%202L8!5e0!3m2!1sen!2sca!4v1707277774499!5m2!1sen!2sca" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
    </div>
    <div className="blueline">
            <div className="divider"></div>
    </div>
    </div>);
}
