/*
import React from 'react';
import ReactDOM from 'react-dom';
import {App} from './App';
import './index.css';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter>
      <App />
  </BrowserRouter>
)
*/

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {App} from './App';
import { createStore, applyMiddleware, } from "redux";
import reducers from "./reducers";
import { Provider } from "react-redux";
import { thunk } from "redux-thunk";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
const reduxStore = createStore(reducers, applyMiddleware(thunk));
root.render(
    <Provider store={reduxStore}>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </Provider>,
);
