import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import './PopupMenu.scss';
import axios from 'axios';
import { SITEGENIE_AUTH_URL, SITEGENIE_URL } from '../../helper';
import { useAuth } from '../../AuthContext';

export const PopupMenu = ({closePopupMenu}) => {
    const { isLoggedIn , logout} = useAuth();
    const [errorMessage, setErrorMessage] = useState('');

    const handleLogin = async () => {
        try {
          const response = await axios.get(SITEGENIE_AUTH_URL);
          if (response.data && response.data.success) {
            const authUrl = response.data.data;
            window.location.href = authUrl;
          } else {
            console.error('Failed to fetch Google authentication URL.');
            setErrorMessage('Failed to initiate Google authentication.');
          }
        } catch (error) {
          console.error('Error:', error);
          console.error('Failed to fetch Google authentication URL.');
          setErrorMessage('Failed to initiate Google authentication.');
        }
      };

      const handleLogout = () => {
        // Clear localStorage
        localStorage.removeItem('accessToken');
        localStorage.removeItem('email');
        logout();
        // Redirect to Homescreen URL
        window.location.href = SITEGENIE_URL;
      };

      /*
      return (<div>
        {
        <div className="popup-menu">
            <div className="popup-menu-content">
              <div className="close-btn" onClick={closePopupMenu}>&times;</div>
              <div className="nav-section" onClick={closePopupMenu}><Link to="/">Welcome</Link></div>
              <div className="nav-section" onClick={closePopupMenu}><Link to="/features">Features</Link></div>
              <div className="nav-section" onClick={closePopupMenu}><Link to="/testimonials">Testimonials</Link></div>
              <div className="nav-section" onClick={closePopupMenu}><Link to="/about">About</Link></div>
              <div className="nav-section" onClick={closePopupMenu}><Link to="/contact">Contact Us</Link></div>
              <div className="nav-section"><Link to="#" onClick={handleLogin}>Login</Link></div>
            //<div className="nav-section"><Link to="#"></Link>{<LoginComponent />}</div>
            </div>
        </div>
        }
    </div>);
    */

    return (<div>
        {
        <div className="dropdown-menu">
        {/*<div className="popup-menu">*/}
              {isLoggedIn ? (
                <div className="popup-menu-content">
                    {/*<div className="close-btn" onClick={closePopupMenu}>&times;</div>*/}
                    <div className="nav-section" onClick={closePopupMenu}><Link to="/project">Project</Link></div>
                    <div className="nav-section" onClick={closePopupMenu}><Link to="/calendar">Canlendar</Link></div>
                    <div className="nav-section" onClick={handleLogout}>Logout</div>
                </div>
                ) : (
                <div className="popup-menu-content">
                    {/*<div className="close-btn" onClick={closePopupMenu}>&times;</div>*/}
                    <div className="nav-section" onClick={closePopupMenu}><Link to="/">Welcome</Link></div>
                    <div className="nav-section" onClick={closePopupMenu}><Link to="/features">Features</Link></div>
                    <div className="nav-section" onClick={closePopupMenu}><Link to="/testimonials">Testimonials</Link></div>
                    <div className="nav-section" onClick={closePopupMenu}><Link to="/about">About</Link></div>
                    <div className="nav-section" onClick={closePopupMenu}><Link to="/contact">Contact Us</Link></div>
                    <div className="nav-section"><Link to="#" onClick={handleLogin}>Login</Link></div>
                </div>
            //<div className="nav-section"><Link to="#"></Link>{<LoginComponent />}</div>
            )}
        </div>
        }
    </div>);
}